// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These
// styles are for the entire app and not just one component. Additionally, this
// file can hold Sass mixins, functions, and placeholder classes to be imported
// and used throughout the application.
* {
  font-family: 'Outfit';
  --ion-font-family: 'Outfit';
}

@font-face {
  font-family: 'Outfit';
  src: url('/assets/fonts/outfit/outfit.ttf') format('truetype');
  font-weight: 100 1000;
}

ion-app.cameraView,
ion-app.cameraView ion-content {
  --background: transparent;
  background: transparent none !important;
}

ion-app.modalCameraView ion-router-outlet {
  visibility: hidden;
}

.hidden {
  display: none !important;
}

.header-logo {
  color: #fff;
  max-height: 4vh;
  margin: 0 auto;
  margin-bottom: 10px;
}

.header-logo-container {
  width: 100%;
  text-align: center;
}

ion-icon.header-logo {
  display: inline-block;
  font-size: 25px;
}

qr-code {
  display: block;
  width: 100%;
  text-align: center;
}

hr {
  background: rgba(175, 175, 175, 0.7);
}

.block {
  display: block;
}

ion-icon {
  &[class*='appname-'] {
    // Instead of using the font-based icons
    // We're applying SVG masks
    mask-size: 70%;
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    background-color: currentColor;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 75% !important;
    width: 1em;
    height: 1em;
  }

  // custom icons
  &[class*='appname-tecservice'] {
    mask-image: url(/assets/icon/tecservice.svg);
  }

  &[class*='appname-expresotec'] {
    mask-image: url(/assets/icon/expresotec.svg);
  }

  &[class*='appname-qr'] {
    mask-image: url(/assets/icon/mealplan.svg);
  }

  &[class*='appname-home'] {
    mask-image: url(/assets/icon/home.svg);
  }

  &[class*='appname-profile'] {
    mask-image: url(/assets/icon/profile2.svg);
  }

  &[class*='appname-gift'] {
    mask-image: url(/assets/icon/gift.svg);
  }

  &[class*='appname-coin'] {
    mask-image: url(/assets/icon/coin.svg);
    /*background: transparent url(/assets/icon/coin.png) no-repeat center;*/
  }
}

ion-segment-button {
  --indicator-color: none;
}

.paypal-checkout-sandbox-iframe {
  border: 0;
}

html.plt-ios .paypal-checkout-sandbox-iframe {
  top: env(safe-area-inset-top) !important;
}

body.scanning-active {
  --ion-background-color: transparent;

  ion-app ion-router-outlet {
    visibility: hidden;
    --background: transparent;
    --ion-background-color: transparent;
  }
}
